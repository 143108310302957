<template>
  <div class="w-100">
    <div class="avatar-banner w-100" :style="[value.bannerId ? { 'background-image': 'url(' + bannerUrl + ')'} : {}]">
      <div v-if="!bannerOnly" class="avatar">
        <div class="avatar-preview-container">
          <b-img-lazy id="avatar-preview" class="image-preview" style="object-fit:cover" :src="avatarUrl" v-if="avatarUrl != null" @error.native="handleAvatarError"/>
          <font-awesome-layers class="fa-9x icon-preview"  v-else>
            <font-awesome-icon :icon="['far', 'circle']" :style="{ color: 'var(--avatar-outer)', fontSize: '8rem' }"/>
            <font-awesome-icon :icon="['fas', 'circle']" :style="{ color: 'var(--avatar-inner)', fontSize: '7.4rem', paddingLeft: '1px' }" />
            <font-awesome-icon :icon="baseAvatarIcon" :transform="transforms" :style="{ color: 'var(--avatar-outer)', paddingLeft: '1px' }" />
          </font-awesome-layers>
          <div class="remove" v-if="!readOnly && value.avatarId">
            <div id="AVATAR_DELETE" class="text times" @click="handleClick('remove-avatar')"><font-awesome-icon :icon="['far', 'xmark']" />
              <b-popover
                target="AVATAR_DELETE"
                placement="top"
                triggers="hover"
                :content="$t('button.delete')">
              </b-popover>
            </div>
          </div>
          <div class="edit" v-if="!readOnly && canList('STORAGE_FILE')">
            <div id="AVATAR_EDIT" class="text" @click="handleClick('edit-avatar')"><font-awesome-icon :icon="['far', 'pen']" />
              <b-popover
                target="AVATAR_EDIT"
                placement="top"
                triggers="hover"
                :content="$t('button.edit')">
              </b-popover>
            </div>
          </div>
        </div>
      </div>
      <div class="banner remove" v-if="!readOnly && value.bannerId">
        <div id="BANNER_DELETE" class="text times" @click="handleClick('remove-banner')"><font-awesome-icon :icon="['far', 'xmark']" />
          <b-popover
            target="BANNER_DELETE"
            placement="top"
            triggers="hover"
            :content="$t('button.delete')">
          </b-popover>
        </div>
      </div>
      <div class="banner edit" v-if="!readOnly && canList('STORAGE_FILE')">
        <div id="BANNER_EDIT" class="text" @click="handleClick('edit-banner')"><font-awesome-icon :icon="['far', 'pen']" />
          <b-popover
            target="BANNER_EDIT"
            placement="top"
            triggers="hover"
            :content="$t('button.edit')">
          </b-popover>
        </div>
      </div>
    </div>

    <FileSelectorModal :show.sync="avatarSelectorShow" mode="BOTH" @ok="avatarOk" :multiple="false" :allowedMimeType="['image/']"/>
    <FileSelectorModal :show.sync="bannerSelectorShow" mode="BOTH" @ok="bannerOk" :multiple="false" :allowedMimeType="['image/']"/>
  </div>
</template>

<script>
/**
 * Purpose:
 * 1. Load avatar and banner images from backend server via API with given uuId.
 * 2. Provide way to change avatar and banner images.
 * 
 * Input:
 * 1. parentId  - Parent entity uuId. Used in file upload.
 * 2. avatarId  - File uuId.
 * 3. bannerId  - File uuId.
 * 
 * Modal:
 * 1. Upload new image and remove old one when save/ok button is clicked. Check out Save() method for details.
 */

import { strRandom } from '@/helpers';
import FileSelectorModal from '@/components/modal/FileSelectorModal';
import { fileService } from '@/services';

export default {
  name: 'AvatarBanner',
  components: {
    FileSelectorModal
  },
  props: {
    id: {
      type: String,
      default: `AVATAR_BANNER_${strRandom(5)}`
    },
    value: {
      type: Object,
      required: true,
      validator: function (obj) {
        return 'avatarId' in obj
          && 'bannerId' in obj
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    baseAvatarIcon: {
      type: Array,
      default: null
    },
    baseAvatarUrl: {
      type: String,
      default: null
    },
    transforms: {
      type: String,
      default: "shrink-6"
    },
    bannerOnly: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      baseUrl: process.env.BASE_URL,
      defaultAvatarUrl: `img/avatars/male.png`,
      avatarSelectorShow: false,
      bannerSelectorShow: false,
      avatarError: false
    }
  },
  
  computed: {
    avatarUrl() {
      return this.value.avatarId && !this.avatarError ? `${this.baseUrl}api/file/${this.value.avatarId}`: this.baseAvatarUrl? this.baseAvatarUrl : this.baseAvatarIcon? null : `${this.baseUrl}${this.defaultAvatarUrl}`;
    },
    bannerUrl() {
      return `${this.baseUrl}api/file/${this.value.bannerId}`;
    }
  },
  methods: {
    handleClick(action) {
      if(action === 'edit-avatar') {
        this.avatarSelectorShow = true;
      } else if (action === 'edit-banner') {
        this.bannerSelectorShow = true;
      } else if (action === 'remove-avatar') {
        this.value.avatarId = null;
      } else if (action === 'remove-banner') {
        this.value.bannerId = null;
      }
    },
    async avatarOk(payload) {
      this.avatarError = false;
      const uuId = payload[0].uuId;
      const result = await this.fileAccessUpdate('avatar', uuId);
      const newValue = { 
        avatarId: uuId, 
        bannerId: this.value.bannerId
      }
      const status = {}
      if(result.hasError) {
        status.alertMsg = result.msg;
        this.$emit('status', status);
      } else {
        this.$emit('input', newValue);
      }
    },
    async bannerOk(payload) {
      const uuId = payload[0].uuId;
      const result = await this.fileAccessUpdate('banner', uuId);
      const newValue = { 
        avatarId: this.value.avatarId, 
        bannerId: uuId
      }
      const status = {}
      if(result.hasError) {
        status.alertMsg = result.msg;
        this.$emit('status', status);
      } else {
        this.$emit('input', newValue);
      }
    },
    async fileAccessUpdate(mode, uuId) {
      const result = {
        hasError: false,
        msg: null
      }
      const form = new FormData();
      form.append('uuId', uuId);
      form.append('accessLevel', 'Public');
      const self = this;
      await fileService.update(form)
      .catch(() => {
        result.hasError = true;
        result.msg = self.$i18n.t('avatar_banner.error.failed_to_set', [self.$i18n.t(`avatar_banner.field.${mode}`)]);
      });
      return result;
    },
    handleAvatarError() {
      this.avatarError = true;
    }
  }
}
</script>

<style lang="scss">
.avatar-banner {
  margin-bottom: 10px;
  background-position: center;
}
</style>
