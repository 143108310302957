var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100" },
    [
      _c(
        "div",
        {
          staticClass: "avatar-banner w-100",
          style: [
            _vm.value.bannerId
              ? { "background-image": "url(" + _vm.bannerUrl + ")" }
              : {},
          ],
        },
        [
          !_vm.bannerOnly
            ? _c("div", { staticClass: "avatar" }, [
                _c(
                  "div",
                  { staticClass: "avatar-preview-container" },
                  [
                    _vm.avatarUrl != null
                      ? _c("b-img-lazy", {
                          staticClass: "image-preview",
                          staticStyle: { "object-fit": "cover" },
                          attrs: { id: "avatar-preview", src: _vm.avatarUrl },
                          nativeOn: {
                            error: function ($event) {
                              return _vm.handleAvatarError.apply(
                                null,
                                arguments
                              )
                            },
                          },
                        })
                      : _c(
                          "font-awesome-layers",
                          { staticClass: "fa-9x icon-preview" },
                          [
                            _c("font-awesome-icon", {
                              style: {
                                color: "var(--avatar-outer)",
                                fontSize: "8rem",
                              },
                              attrs: { icon: ["far", "circle"] },
                            }),
                            _c("font-awesome-icon", {
                              style: {
                                color: "var(--avatar-inner)",
                                fontSize: "7.4rem",
                                paddingLeft: "1px",
                              },
                              attrs: { icon: ["fas", "circle"] },
                            }),
                            _c("font-awesome-icon", {
                              style: {
                                color: "var(--avatar-outer)",
                                paddingLeft: "1px",
                              },
                              attrs: {
                                icon: _vm.baseAvatarIcon,
                                transform: _vm.transforms,
                              },
                            }),
                          ],
                          1
                        ),
                    !_vm.readOnly && _vm.value.avatarId
                      ? _c("div", { staticClass: "remove" }, [
                          _c(
                            "div",
                            {
                              staticClass: "text times",
                              attrs: { id: "AVATAR_DELETE" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleClick("remove-avatar")
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "xmark"] },
                              }),
                              _c("b-popover", {
                                attrs: {
                                  target: "AVATAR_DELETE",
                                  placement: "top",
                                  triggers: "hover",
                                  content: _vm.$t("button.delete"),
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    !_vm.readOnly && _vm.canList("STORAGE_FILE")
                      ? _c("div", { staticClass: "edit" }, [
                          _c(
                            "div",
                            {
                              staticClass: "text",
                              attrs: { id: "AVATAR_EDIT" },
                              on: {
                                click: function ($event) {
                                  return _vm.handleClick("edit-avatar")
                                },
                              },
                            },
                            [
                              _c("font-awesome-icon", {
                                attrs: { icon: ["far", "pen"] },
                              }),
                              _c("b-popover", {
                                attrs: {
                                  target: "AVATAR_EDIT",
                                  placement: "top",
                                  triggers: "hover",
                                  content: _vm.$t("button.edit"),
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.readOnly && _vm.value.bannerId
            ? _c("div", { staticClass: "banner remove" }, [
                _c(
                  "div",
                  {
                    staticClass: "text times",
                    attrs: { id: "BANNER_DELETE" },
                    on: {
                      click: function ($event) {
                        return _vm.handleClick("remove-banner")
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "xmark"] },
                    }),
                    _c("b-popover", {
                      attrs: {
                        target: "BANNER_DELETE",
                        placement: "top",
                        triggers: "hover",
                        content: _vm.$t("button.delete"),
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          !_vm.readOnly && _vm.canList("STORAGE_FILE")
            ? _c("div", { staticClass: "banner edit" }, [
                _c(
                  "div",
                  {
                    staticClass: "text",
                    attrs: { id: "BANNER_EDIT" },
                    on: {
                      click: function ($event) {
                        return _vm.handleClick("edit-banner")
                      },
                    },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["far", "pen"] },
                    }),
                    _c("b-popover", {
                      attrs: {
                        target: "BANNER_EDIT",
                        placement: "top",
                        triggers: "hover",
                        content: _vm.$t("button.edit"),
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c("FileSelectorModal", {
        attrs: {
          show: _vm.avatarSelectorShow,
          mode: "BOTH",
          multiple: false,
          allowedMimeType: ["image/"],
        },
        on: {
          "update:show": function ($event) {
            _vm.avatarSelectorShow = $event
          },
          ok: _vm.avatarOk,
        },
      }),
      _c("FileSelectorModal", {
        attrs: {
          show: _vm.bannerSelectorShow,
          mode: "BOTH",
          multiple: false,
          allowedMimeType: ["image/"],
        },
        on: {
          "update:show": function ($event) {
            _vm.bannerSelectorShow = $event
          },
          ok: _vm.bannerOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }